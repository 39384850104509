@import "../../../../scss/theme-bootstrap";

.text-tout {
  &__content-wrapper {
    margin: 0 auto;
  }
  &.content-block {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
    border: 0;
    .content-block__line {
      display: block;
    }
  }
}
